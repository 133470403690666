import proj4 from 'proj4';
window.proj4 = proj4;

import Highcharts from 'highcharts';

import stock from 'highcharts/modules/stock';
import gantt from 'highcharts/modules/gantt';
import map from 'highcharts/modules/map';
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data';
import offlineExporting from 'highcharts/modules/offline-exporting';
import seriesLabel from 'highcharts/modules/series-label';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';

stock(Highcharts);
gantt(Highcharts);
map(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
offlineExporting(Highcharts);
seriesLabel(Highcharts);
noDataToDisplay(Highcharts);

const defaultOptions = {
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        loading: gettext('Loading data...'),
        months: [
            gettext('January'), gettext('February'), gettext('March'), gettext('April'),
            gettext('May'), gettext('June'), gettext('July'), gettext('August'),
            gettext('September'), gettext('October'), gettext('November'), gettext('December')
        ],
        weekdays: [
            gettext('Sunday'), gettext('Monday'), gettext('Tuesday'), gettext('Wednesday'),
            gettext('Thursday'), gettext('Friday'), gettext('Saturday')
        ],
        shortMonths: [
            gettext('Jan'), gettext('Feb'), gettext('Mar'), gettext('Apr'),
            gettext('May'), gettext('Jun'), gettext('Jul'), gettext('Aug'),
            gettext('Sep'), gettext('Oct'), gettext('Nov'), gettext('Dec')
        ],
        noData: gettext('No data to display'),

        viewFullscreen: gettext('View in full screen'),
        exitFullscreen: gettext('Exit from full screen'),

        exportButtonTitle: gettext('Export'),

        printButtonTitle: gettext('Print'),
        printChart: gettext('Print chart'),

        rangeSelectorFrom: gettext('From'),
        rangeSelectorTo: gettext('To'),
        rangeSelectorZoom: gettext('Zoom'),

        downloadPNG: gettext('Download as PNG image'),
        downloadJPEG: gettext('Download as JPEG image'),
        downloadPDF: gettext('Download as PDF document'),
        downloadSVG: gettext('Download as SVG image'),

        downloadCSV: gettext('Download as CSV'),
        downloadXLS: gettext('Download as XLS'),

        resetZoom: gettext('Reset zoom'),
        resetZoomTitle: gettext('Reset zoom')
    },
    credits: {
        enabled: false,
    },
    exporting: {
        buttons: {
            contextButton: {
                menuItems: [
                    "viewFullscreen",
                    "printChart",
                    "separator",
                    "downloadPNG",
                    "downloadJPEG",
                    // "downloadPDF",
                    "downloadSVG",
                    "separator",
                    "downloadCSV",
                    "downloadXLS",
                ]
            }
        },
        scale: 1,
        sourceWidth: 1920,
        sourceHeight: 1080,
        fallbackToExportServer: false,
        error: function () {
            toastr.error('An error occurred while exporting. Retry later');
        }
    }
}

Highcharts.setOptions(defaultOptions);
window.Highcharts = Highcharts;
